import { observable } from "mobx";
import axios from 'axios';

class AppState
{
    @observable appname = "EasyStarter";
    @observable fields = {};    

    async get_config( module )
    {
        const url = '/modules/'+module+'.json';
        const ret = await axios.get( url );
        // console.log( ret )
        return ret.data;
    }

    async save_docker_file( file_content )
    {
        var params = new URLSearchParams();
        params.append("key", "VFhbZrJv%ARzkf8efe");
        params.append("docker", file_content);
        const ret = await axios.post( 'https://playcode.applinzi.com/install/save.php', params );

        return ret.data;
    }
}

export default new AppState();