import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLine from './TextLine';

@withRouter
@inject("store")
@observer
export default class ConfigLine extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const item = this.props.data;
        if( !item ) return null;

        const help_text = item.changeit == 1 ? item.var : '🚫 不熟勿改 '+item.var;
        
        return <div>
            <TextLine label={item.cn} help={help_text} field={item.var} />
        </div>;
    }
}