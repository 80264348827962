import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import ConfigLine from '../component/ConfigLine'; 
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button, Callout } from '@blueprintjs/core';
import { saveAs } from 'file-saver';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from '../util/Function';
import { Base64 } from 'js-base64';

@withRouter
@inject("store")
@observer
export default class Config extends Component
{
    state = {"fields":[],"yml":false,"url":false,"source":false,"command":""};
    
    async componentDidMount()
    {
        const module = this.props.match.params.module;
        if( !module ) return null;
        const info = await this.props.store.get_config(module);
        if( info ) this.setState(info);
        if( info.fields )
        {
            for( const item of info.fields )
            {
                if(!this.props.store.fields[item.var]) this.props.store.fields[item.var] = item.default;
            }
        }
    }

    replace()
    {
        let yml = this.state.yml;
        for( const f of this.state.fields )
        {
            const new_var = this.props.store.fields[f.var] || f.default;

            yml = yml.replaceAll( '${{{'+f.var+'}}}' , new_var );

        }
        yml += "\r\n# 本文件由方糖Reload（01.ftqq.com）自动生成";
        return yml;
    }

    download( yml )
    {
        saveAs( new Blob( [yml] , {type: "text/plain;charset=utf-8"} ) , 'docker-compose.yml' );
    }

    async build_command( yml, short = false )
    {
        // curl -sSL "http://playcode.vipsinaapp.com/install/read.php?name=DCFILE_30a434957bf2257943769a9272082ad7" > docker-compose.yml && sudo apt-get update && sudo apt-get install docker.io -y && sudo apt install docker-compose -y  && sudo docker-compose up -d 

        const yml_base64 = Base64.encode( yml );
        const command = short ? `echo "${yml_base64}" | base64 -d  > 'docker-compose.yml' && sudo docker-compose up -d` : `echo "${yml_base64}" | base64 -d  > 'docker-compose.yml' && sudo apt-get update && sudo apt-get install docker.io -y && sudo apt install docker-compose -y  && sudo docker-compose up -d`;
        this.setState({command});
        console.log( command );
        // const ret = await this.props.store.save_docker_file( yml );
        // if( ret && ret.substring(0,7) == 'http://' )
        // {
        //     this.setState({"url":ret});
        // }
    }

    render()
    {
        const fields = this.state.fields.sort( (a , b) => b.changeit - a.changeit );
        
        if( !fields ) return null;
        const yml = this.replace();
        // const command = 'curl -sSL "' + this.state.url + '" > docker-compose.yml && sudo apt-get update && sudo apt-get install docker.io -y && sudo apt install docker-compose -y  && sudo docker-compose up -d ';
        const command = this.state.command;

        const main = <div className="p-5">
            {fields && fields.map( item => <ConfigLine key={item.var} data={item} /> )}
            
            { yml && <>
                <div className="button-line py-5">
                    <Button large={true} onClick={()=>this.build_command(yml)}>生成安装命令·含Docker环境</Button>

                    <Button large={true} onClick={()=>this.build_command(yml,true)}>短安装命令</Button>

                    此命令通过腾讯云Ubuntu20.4镜像测试，<a href="https://curl.qcloud.com/VPjlS4gj" target="_blank">点此购买</a>

                </div>
                {this.state.command && <>
                <Callout className="my-5" intent="Danger">此命令中包含刚才填写的信息，请勿分享给他人
                <CopyToClipboard text={command} onCopy={() => toast("已复制到剪贴板")}>
                    <Button large={true} className="ml-5">点此复制</Button>
                </CopyToClipboard>
                </Callout>
                <SyntaxHighlighter language="docker-compose" style={atomOneDark} className="yml-code">
                 {command}
                </SyntaxHighlighter></>}

                <div className="std-hr" />

                <div className="button-line py-5">
                <Button className="pb-5" large={true} onClick={()=>this.setState({"source":!this.state.source})}>查看 docker-compose 文件</Button>
                <Button className="pb-5" large={true} onClick={()=>this.download(yml)}>下载</Button>
                <CopyToClipboard text={yml} onCopy={() => toast("已复制到剪贴板")}>
                    <Button className="pb-5" large={true} >复制</Button>
                </CopyToClipboard>
                </div>

                {this.state.source && <SyntaxHighlighter language="docker-compose" style={atomOneDark} className="yml-code">
                 {yml}
                </SyntaxHighlighter>}
                
            </> }
            
        </div>;
        return <div>{main}</div>;
    }
}